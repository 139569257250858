.MuiDropzonePreviewList-removeButton {
    top: -8px;
    right: 40px !important;
    width: 40px;
    height: 40px;
    opacity: 0;
    position: absolute;
    transition: .5s ease;
}

.MuiSvgIcon-root {
    cursor: pointer;
}

.error {
    color: red;
}